import React from 'react'
import Img from 'gatsby-image'

// Components
import DefaultCarousel from 'src/components/DefaultCarousel'

// Assets
import Smartphone from 'src/images/smartphone.png'
import StatusBar from 'src/images/status-bar.png'
import NavigationBar from 'src/images/navigation-bar.png'

// Types
import { Image, ProjectData } from '../types'

// Styles
import ProjectViewStyle from './style'

type ProjectViewProps = {
  projectData: ProjectData;
}

const ProjectView = ({ projectData }: ProjectViewProps) => {
  return (
    <ProjectViewStyle>
      <img src={Smartphone} alt='Smartphone' title='Smartphone' />
      <div className='gallery-wrapper'>
        <img className='status-bar' src={StatusBar} alt='Status Bar' title='Status Bar' />
        <DefaultCarousel md={{ items: 1 }} lg={{ items: 1 }} xl={{ items: 1 }}>
          {
            projectData.images.map((img: Image) => (
              <Img key={img.id} fluid={img.localFile.childImageSharp.fluid} />
            ))
          }
        </DefaultCarousel>
        <img className='navigation-bar' src={NavigationBar} alt='Navigation Bar' title='Navigation Bar' />
      </div>
    </ProjectViewStyle>
  )
}

export default ProjectView
