import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Row } from 'src/styles/grid'
import breakpoints from 'src/styles/breakpoints'

export const PortfolioSection = styled.section`
  padding: 2rem 0;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};

  ${Row} {
    margin-top: 3rem;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 6rem 0;

    ${Row} {
      margin-top: 4rem;
    }
  }
`
