import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'

import styled, { ThemeProps, DefaultTheme } from 'styled-components'

export const Nav = styled.nav`

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: ${spaces.xxl} 0;

    li {
      margin: ${spaces.xs};
      flex-shrink: 0;

      button {
        height: 37px;
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
        border: 1px solid ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
        text-transform: uppercase;
        font-size: ${fonts.xs};
        border-radius: 3px;
        padding: ${spaces.md};
        font-weight: bold;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        &.active {
          border: 1px solid ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
          background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
        }
      }
    }
  }
`

export const Content = styled.div`

  > div {
    display: none;

    &.active {
      display: block;
    }
  }
`
