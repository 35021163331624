import styled, { keyframes, ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

import { ButtonContainer } from 'src/components/Button/style'

const pulse = keyframes`
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
`

export const TestimonialSection = styled.section`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  padding: ${spaces.xxl} 0;
  position: relative;

  .no-comments {
    font-size: ${fonts.lg};
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    margin: ${spaces.xxl} 0;
  }

  ${ButtonContainer} {
    margin: auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 5rem;

    ${ButtonContainer} {
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    header {
      /* position: absolute; */
      /* top: 70px; */
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 6rem;
  }

`

export const TestimonialSlider = styled.div`

  @media (min-width: ${breakpoints.lg}) {
    margin-top: -100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transform: translateZ(0);
  }

  .react-multi-carousel {

    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media (min-width: ${breakpoints.lg}) {
        max-width: 56%;
        width: 56%;
        justify-content: flex-start;
      }

      .content {

        &__testimonial {
          font-size: ${fonts.lg};
          font-weight: 300;
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
          margin: 4rem 0;
          line-height: 1.2;
          text-align: center;

          @media (min-width: ${breakpoints.lg}) {
            text-align: left;
            font-size: ${fonts.xl};
          }
        }

        &__info {
          font-size: ${fonts.md};
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
          margin-bottom: ${spaces.md};
          text-align: center;

          @media (min-width: ${breakpoints.lg}) {
            text-align: left;
          }

          strong {
            font-weight: 600;
            display: block;
            margin-bottom: ${spaces.sm};
          }
        }
      }
    }

    &-dot-list {
      width: 100%;
      position: relative;
      padding: 30px 0;
      overflow-x: auto;
      justify-content: space-between;

      @media (min-width: ${breakpoints.lg}) {
        padding-left: 25px;
        max-width: 40%;
        height: 440px;
        width: 40%;
        overflow-x: initial;
      }

      li {
        border-radius: 100%;
        position: relative;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        width: 50px;
        height: 50px;
        margin: 0 ${spaces.md};
        border: none;
        flex-shrink: 0;

        &.active {
          transform: scale(1.1);
        }

        @media (min-width: ${breakpoints.lg}) {
          position: absolute;

          &.active {
            transform: scale(1.3);
            box-shadow: rgba(22, 30, 54, 0.25) 0px 18px 68px 0px;

            &::before, &::after {
              content: "";
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              top: 50%;
              left: 50%;
              opacity: 0;
              transform: translate(-50%, -50%);
              backface-visibility: hidden;
              pointer-events: none;
              border-radius: 50%;
              box-shadow: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary} 0px 0px 0px 0.8px;
              z-index: 10;
            }

            &::before {
              animation: 2.2s ease-out 0s infinite normal none running ${pulse};
            }

            &::after {
              animation: 2.2s ease-out 1s infinite normal none running ${pulse};
            }
          }

          &:nth-child(1) {
            top: 180px;
            left: 40px;
            height: 105px;
            width: 105px;
          }
          &:nth-child(2) {
            top: 20px;
            right: 120px;
            height: 95px;
            width: 95px;
          }
          &:nth-child(3) {
            top: 150px;
            right: 0;
            height: 65px;
            width: 65px;
          }
          &:nth-child(4) {
            top: 260px;
            right: 20px;
            height: 85px;
            width: 85px;
          }
          &:nth-child(5) {
            top: 350px;
            left: 120px;
            height: 100px;
            width: 100px;
          }
        }

        button {
          overflow: hidden;
          border-radius: 100%;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }

          .gatsby-image-wrapper {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .react-multiple-carousel {

    &__arrow {
      background: transparent;
      font-weight: bold;
      z-index: 1;
      position: relative;

      &--left {
        left: 25%;
      }

      &--right {
        left: 45%;
      }

      @media (min-width: ${breakpoints.md}) {
        &--left {
          left: 38%;
        }

        &--right {
          left: 49%;
        }
      }

      @media (min-width: ${breakpoints.lg}) {
        position: fixed;
        bottom: 30px;

        &--left {
          transition: all 0.25s ease-in-out 0s;
          left: -10px;
        }

        &--right {
          transition: all 0.25s ease-in-out 0s;
          left: 70px;
        }
      }

      @media (min-width: ${breakpoints.xl}) {
        bottom: 50px;
      }

      &::before {
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      }

    }
  }

`
