import React from 'react'

// Components
import HeaderSection from 'src/components/HeaderSection'
import ContactForm from 'src/components/ContactForm'
import SocialNetwork from 'src/components/SocialNetwork'

// Styles
import { Container, Row, Col } from 'src/styles/grid'
import { ContactSection } from './style'

function Contact () {
  return (
    <ContactSection id='contact'>
      <Container>
        <Row centered={true}>
          <Col md={6}>
            <HeaderSection title='Entre em contato' subtitle='Envie uma mensagem' />
            <p className='description'>
              Você pode entrar em contato comigo enviando uma mensagem no meu email
              ou através do meu Linkedin.
            </p>
            <div className='email'>
              <p><strong>Email</strong></p>
              <p>thiagosalome@gmail.com</p>
            </div>
            <SocialNetwork />
          </Col>
          <Col md={6} lg={5}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </ContactSection>
  )
}

export default Contact
