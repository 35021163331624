import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const ExperienceSection = styled.section`
  padding: 2rem 0;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};

  @media (min-width: ${breakpoints.md}) {
    padding: 3rem 0;
  }
`
