import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Container = styled.div`
  position: relative;
  margin: 1rem 0;
  align-items: flex-end;

  .wrapper {

    svg {
      width: 100%;
      height: 340px;

      path {

        &:first-child {
          fill: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
          opacity: 0.9;
        }

        &:last-child {
          fill: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        }
      }
    }

    @media (min-width: ${breakpoints.lg}) {
      svg {
        height: 600px;
      }
    }
  }

  .programer {
    max-width: 100%;
    position: absolute;

    > div {
      max-width: 100%;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 5rem 0 8rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0px;
    right: 0px;
    margin: 0;

    .wrapper {
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 83%;
      box-sizing: border-box;
      margin: 0;
      overflow: hidden;

      svg {
        transform: translateX(10%);
        height: 520px;
      }
    }

    .programer {
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      height: auto;
    }
  }

`
