import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Link } from 'gatsby'

import breakpoints from 'src/styles/breakpoints'
import spaces from 'src/styles/spaces'
import fonts from 'src/styles/fonts'

export const BlogSection = styled.section`
  padding: 2rem 0;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};

  @media (min-width: ${breakpoints.md}) {
    padding: 4rem;
  }
`

export const BlogArticle = styled(Link)`
  display: block;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    cursor: pointer;

    figure {

      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }
  }

  figure {
    overflow: hidden;

    .gatsby-image-wrapper {
      transition: all 0.3s ease-in-out;
    }
  }

  > div {
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
    padding: ${spaces.xl};

    h4 {
      font-weight: bold;
      font-size: ${fonts.sm};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      text-transform: uppercase;
      line-height: 17px;
      height: 34px;
      overflow: hidden;
    }

    span {
      margin: ${spaces.sm} 0;
      font-size: ${fonts.xs};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
      display: block;
    }

    p {
      font-size: ${fonts.sm};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      line-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

`

export const BlogGrid = styled.div`
  display: grid;
  padding: 1rem 0;
  gap: 1.5rem;
  grid-template-columns: repeat(4, minmax(255px, 1fr));
  overflow-x: auto;

  @media (min-width: ${breakpoints.xl}) {
    gap: 2.5rem;
    overflow: initial;
  }

  ${BlogArticle} {

    &:nth-child(1) {
      grid-row: 1 / 8;

      .gatsby-image-wrapper {
        height: 185px;
      }

      p {
        -webkit-line-clamp: 4;
      }
    }

    &:nth-child(2) {
      grid-row: 1 / 6;

      .gatsby-image-wrapper {
        height: 125px;
      }

      p {
        -webkit-line-clamp: 2;
      }
    }

    &:nth-child(3) {
      grid-row: 1 / 8;

      .gatsby-image-wrapper {
        height: 185px;
      }

      p {
        -webkit-line-clamp: 4;
      }
    }

    &:nth-child(4) {
      grid-row: 1 / 8;

      .gatsby-image-wrapper {
        height: 185px;
      }

      p {
        -webkit-line-clamp: 4;
      }
    }

    &:nth-child(5) {
      grid-row: 8 / 13;
      grid-column-start: 1;

      .gatsby-image-wrapper {
        height: 125px;
      }

      p {
        -webkit-line-clamp: 2;
      }
    }

    &:nth-child(6) {
      grid-row: 6 / 13;

      .gatsby-image-wrapper {
        height: 185px;
      }

      p {
        -webkit-line-clamp: 4;
      }
    }

    &:nth-child(7) {
      grid-row: 8 / 13;
      grid-column: span 2;

      .gatsby-image-wrapper {
        height: 145px;
      }

      h4 {
        height: 17px;
      }

      p {
        -webkit-line-clamp: 2;
      }
    }

  }
`
