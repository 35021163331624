import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import colors from 'src/styles/colors'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'

export const Wrapper = styled.div`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: ${spaces.xxl};

  > div > p {
    font-size: ${fonts.sm};
    text-align: center;
    margin-bottom: ${spaces.sm};
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
  }
`

export const ProjectListContent = styled.div`
  ul {
    overflow-y: scroll;
    height: 200px;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${colors.gray[200]};
      border-radius: 10px;
    }

    li {

      a {
        display: flex;
        align-items: center;
        margin: ${spaces.sm};
        padding: ${spaces.xs} ${spaces.lg};
        cursor: pointer;

        &.active,&:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        img {
          max-width: 25px;
        }

        p {
          font-size: ${fonts.sm};
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
          margin-left: ${spaces.md};
        }
      }
    }
  }
`
