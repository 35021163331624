import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import colors from 'src/styles/colors'
import { rotate } from 'src/styles/animations'

export const FormContainer = styled.form`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: 4rem 3rem;
  overflow: hidden;

  h4 {
    font-size: ${fonts.md};
    font-weight: bold;
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    text-transform: uppercase;
    margin-bottom: 4rem;
  }

  .field {
    position: relative;
    margin: ${spaces.xxl} 0;

    &.focus {

      label {
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        top: -15px;
        font-size: ${fonts.xs};
      }
    }

    label {
      font-size: ${fonts.xs};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      text-transform: uppercase;
      position: absolute;
      transition: all 0.3s ease-in-out;
      top: 5px;
    }

    input, textarea {
      border: none;
      background-color: transparent;
      padding: ${spaces.xs} 0;
      outline: none;
      width: 100%;
      font-size: ${fonts.sm};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      position: relative;
    }

    &__error {
      margin-top: 5px;
      display: block;
      font-size: 10px;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    }
  }

  .submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${spaces.md};

    input {
      border: none;
      background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      border-radius: 3px;
      padding: ${spaces.md} ${spaces.lg};
      text-transform: uppercase;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
      font-weight: bold;
      font-size: ${fonts.xs};
      cursor: pointer;

      &:disabled {
        opacity: 0.8;
        cursor: initial;
      }
    }
  }

  .loading {
    text-align: center;

    svg {
      width: 30px;
      height: 30px;
      margin: 0 ${spaces.lg};
      animation: ${rotate} 2s linear infinite;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    }
  }

  .message {
    background-color: ${colors.gray[500]};
    width: 100%;
    border-radius: 3px;
    padding: ${spaces.md} ${spaces.xl};
    color: ${colors.white};
    font-size: ${fonts.sm};
  }
`
