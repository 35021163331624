import React from 'react'
import ReactGa from 'react-ga'
import { PageProps } from 'gatsby'

// Components
import Layout from 'src/layout'
import SEO from 'src/components/seo'

// Containers
import Banner from 'src/containers/Banner'
import About from 'src/containers/About'
import Experience from 'src/containers/Experience'
import Skills from 'src/containers/Skills'
import Portfolio from 'src/containers/Portfolio'
import Testimonial from 'src/containers/Testimonial'
import Contact from 'src/containers/Contact'
import Blog from 'src/containers/Blog'

// Analytics
if (process.env.NODE_ENV === 'production') {
  ReactGa.initialize('UA-165025947-1')
}

function IndexPage ({ location }: PageProps) {
  return (
    <Layout>
      <SEO url={location.pathname} />
      <Banner />
      <About />
      <Experience />
      <Skills />
      <Portfolio />
      <Blog />
      <Testimonial />
      <Contact />
    </Layout>
  )
}

export default IndexPage
