/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo, useState, MouseEvent } from 'react'
import Img, { GatsbyImageFixedProps } from 'gatsby-image'
import { ValueType } from 'react-select'
import { OptionProps } from 'react-select/src/types'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import PortfolioTab from 'src/components/Tabs/PortfolioTab'
import Select from 'src/components/Select'

// Helpers
import scrollTo from 'src/helpers/scrollTo'
import { trackClick } from 'src/helpers/ga'

// Types
import { ProjectData, Image } from '../types'

// Styles
import { Wrapper, ProjectListContent } from './style'

type Skill = {
  id: string;
  name: string;
}

type Project = {
  id: string;
  date: string;
  type: string;
  title: string;
  github: string;
  link: string;
  description: string;
  features: string;
  images: Image[];
  icon: {
    childImageSharp: GatsbyImageFixedProps;
  };
}

type Filter = {
  type_project: string;
  technologies: ValueType<OptionProps, true>;
}

type ProjectListProps = {
  setProjectData: (value: ProjectData) => void;
}

const ProjectList = ({ setProjectData }: ProjectListProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProject(sort: {fields: date, order: DESC}) {
        nodes {
          id
          date(formatString: "DD/MM/YYYY")
          type
          title
          github
          link
          description
          features
          icon {
            childImageSharp {
              fixed(width: 25, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          images {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      allStrapiSkill(filter: {state: {eq: "studied"}}) {
        nodes {
          id
          name
        }
      }
    }
  `)
  const [ filterBy, setFilterBy ] = useState('type_project') // type_project, technologies
  const [ filter, setFilter ] = useState<Filter>({
    type_project: 'web',
    technologies: [],
  })
  const [ activeProject, setActiveProject ] = useState<number>(0)
  const skillsOptions = data.allStrapiSkill.nodes.map((skill: Skill) => ({
    label: skill.name,
    value: skill.id,
  }))
  const projectList = useMemo(() => {
    let filteredProjects = []
    if (filterBy === 'type_project') {
      const re = new RegExp(filter.type_project, 'gi')
      filteredProjects = data.allStrapiProject.nodes.filter((project: Project) => project.type.match(re))
    } else {
      if (filter.technologies) {
        const technologyLabels = filter.technologies.map((technology: OptionProps) => technology.label)
        const re = new RegExp(technologyLabels.join('|'), 'gi')
        filteredProjects = data.allStrapiProject.nodes.filter((project: Project) => project.features.match(re))
      }
    }

    return filteredProjects.map((project: Project, index: number) => {
      if (activeProject === index) {
        setProjectData({
          title: project.title,
          description: project.description,
          features: project.features,
          link: project.link,
          github: project.github,
          date: project.date,
          images: project.images,
        })
      }

      return (
        <li key={project.id} className={activeProject === index ? 'active' : ''}>
          <a href='#smartphone' title={project.title} onClick={(evt: MouseEvent) => handleProject(evt, index) }>
            <Img fixed={project.icon.childImageSharp.fixed} alt={project.title} title={project.title} />
            <p>{project.title}</p>
          </a>
        </li>
      )
    })
  }, [ filterBy, filter, activeProject ])

  function handleTab (type_project: string) {
    setFilter({
      ...filter,
      type_project,
    })
    setActiveProject(0)
    trackClick('Portfólio', 'Clique em tipo de projeto', type_project)
  }

  function handleSelect (options: ValueType<OptionProps, true>) {
    setFilter({
      ...filter,
      technologies: options || [],
    })
    setActiveProject(0)
  }

  function handleProject (evt: MouseEvent, project: number) {
    evt.preventDefault()
    const anchor = evt.currentTarget as HTMLAnchorElement
    if (window.innerWidth < 768) {
      scrollTo(evt)
    }
    setActiveProject(project)
    trackClick('Portfólio', 'Clique em projeto', anchor.title)
  }

  return (
    <Wrapper>
      <div>
        <p>Filtrar por</p>
        <PortfolioTab
          list={[
            {
              id: 'type_project',
              label: 'Tipo de Projeto',
            },
            {
              id: 'technologies',
              label: 'Tecnologias',
            },
          ]}
          state={filterBy}
          setState={setFilterBy}
        />
      </div>
      <div>
        {
          filterBy === 'type_project'
            ? (
              <>
                <p>Tipo de projeto</p>
                <PortfolioTab
                  list={[
                    {
                      id: 'web',
                      label: 'Web',
                    },
                    {
                      id: 'mobile',
                      label: 'Mobile',
                    },
                  ]}
                  state={filter.type_project}
                  setState={handleTab}
                />
              </>
            )
            : (
              <>
                <p>Tecnologias</p>
                <Select
                  isMulti
                  onChange={handleSelect}
                  options={skillsOptions}
                />
              </>
            )
        }
      </div>
      <div>
        <p>Projetos</p>
        <ProjectListContent>
          <ul>
            {projectList}
          </ul>
        </ProjectListContent>
      </div>
    </Wrapper>
  )
}

export default ProjectList
