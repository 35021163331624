import styled, { css, ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

type HeaderContainer = {
  centered?: boolean;
}

export const HeaderContainer = styled.header`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }

  ${(props: HeaderContainer) => {
    if (props.centered) {
      return (
        css`
          @media (min-width: ${breakpoints.md}) {
            text-align: center;
          }
        `
      )
    }
  }}


  h3 {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${fonts.md};
    margin: ${spaces.lg} 0;
  }

  p {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    font-weight: 600;
    font-size: ${fonts.xl};
  }
`
