import React, { ReactNode, ReactElement, MouseEvent } from 'react'

// Styles
import { ButtonContainer } from './style'

type Button = {
  icon?: ReactElement;
  children: ReactNode;
  href: string;
  title: string;
  onClick?: (evt: MouseEvent) => void;
}

function Button ({ icon, children, href, title, onClick }: Button) {
  return (
    <ButtonContainer title={title} href={href} onClick={onClick}>
      {icon}
      <span>{children}</span>
    </ButtonContainer>
  )
}

export default Button
