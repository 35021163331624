import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'

export default styled.div`

  h4 {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    font-size: ${fonts.xl};
    font-weight: 600;
  }

  > div {
    margin: ${spaces.xl} 0;

    p {
      font-size: ${fonts.sm};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};

      strong {
        font-weight: 600;
        display: block;
        margin-bottom: ${spaces.sm};
      }
    }
  }

  .links {

    a {
      display: inline-block;
      margin: 0 ${spaces.sm};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

`
