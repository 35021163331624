import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
// import { FiDownload } from 'react-icons/fi'

// Components
import HeaderSection from 'src/components/HeaderSection'
// import Button from 'src/components/Button'

// Styles
import { Container, Row, Col } from 'src/styles/grid'
import { AboutSection } from './style'

function About () {
  const dataImage = useStaticQuery(graphql`
    query {
      profile: imageSharp(fluid: {originalName: { regex: "/perfil/" }}) {
        fluid(maxWidth: 285, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)
  return (
    <AboutSection id='about'>
      <Container>
        <Row centered={true}>
          <Col md={5} lg={3}>
            <figure className='perfil'>
              <Img fluid={dataImage.profile.fluid} alt='Imagem de Perfil' title='Imagem de Perfil' />
            </figure>
          </Col>
          <Col md={7} lg={6}>
            <HeaderSection title='Sobre mim' subtitle='Uma breve descrição' />
            <div className='description'>
              <p>
                Apaixonado por código e criação, procuro estar sempre aprendendo algo novo e buscando boas referências,
                implementando uma ideia diferente a cada desafio. Me sinto muito motivado quando participo de projetos
                que me agregam novos conhecimentos. Além do mais, gosto de poder compartilhá-los com outras pessoas,
                contribuindo para a comunidade de desenvolvimento como um todo.
              </p>
              <p>
                Atualmente sou formado em Sistemas de Informação pela PUC Minas e trabalho como Desenvolvedor
                Front-end III no Banco Inter.
              </p>
            </div>
            {/* <Button icon={<FiDownload />}>Baixar currículo</Button> */}
          </Col>
        </Row>
      </Container>
    </AboutSection>
  )
}

export default About
