import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { IoMdCodeWorking } from 'react-icons/io'
import { GoBook, GoLightBulb } from 'react-icons/go'

import Img, { GatsbyImageFixedProps } from 'gatsby-image'

// Components
import HeaderSection from 'src/components/HeaderSection'
import DefaultCarousel from 'src/components/DefaultCarousel'

// Styles
import colors from 'src/styles/colors'
import { Container } from 'src/styles/grid'
import { SkillSection, SkillCard } from './style'

type Skill = {
  name: string;
  state: string;
  image: {
    childImageSharp: GatsbyImageFixedProps;
  };
}

function Skills () {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSkill {
        nodes {
          name
          state
          image {
            childImageSharp {
              fixed(width: 30, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const studiedSkills = data.allStrapiSkill.nodes.filter((skill: Skill) => skill.state === 'studied')
  const studyingSkills = data.allStrapiSkill.nodes.filter((skill: Skill) => skill.state === 'studying')
  const toStudySkills = data.allStrapiSkill.nodes.filter((skill: Skill) => skill.state === 'tostudy')

  return (
    <SkillSection id='skills'>
      <Container>
        <HeaderSection centered={true} title='Habilidades Técnicas' subtitle='Meus principais conhecimentos' />
        <DefaultCarousel>
          <SkillCard>
            <div className='icon'>
              <GoLightBulb color={colors.white} size={30} />
            </div>
            <div className='content'>
              <h4>Conhecimentos já aplicados</h4>
              <ul>
                {
                  studiedSkills.map((skill: Skill) => (
                    <li key={skill.name}>
                      <Img fixed={skill.image.childImageSharp.fixed} alt={skill.name} />
                      <p>{skill.name}</p>
                    </li>
                  ))
                }
              </ul>
            </div>
          </SkillCard>
          <SkillCard>
            <div className='icon'>
              <IoMdCodeWorking color={colors.white} size={35} />
            </div>
            <div className='content'>
              <h4>Conhecimentos em desenvolvimento</h4>
              <ul>
                {
                  studyingSkills.map((skill: Skill) => (
                    <li key={skill.name}>
                      <Img fixed={skill.image.childImageSharp.fixed} alt={skill.name} />
                      <p>{skill.name}</p>
                    </li>
                  ))
                }
              </ul>
            </div>
          </SkillCard>
          <SkillCard>
            <div className='icon'>
              <GoBook color={colors.white} size={30} />
            </div>
            <div className='content'>
              <h4>Conhecimentos a serem desenvolvidos</h4>
              <ul>
                {
                  toStudySkills.map((skill: Skill) => (
                    <li key={skill.name}>
                      <Img fixed={skill.image.childImageSharp.fixed} alt={skill.name} />
                      <p>{skill.name}</p>
                    </li>
                  ))
                }
              </ul>
            </div>
          </SkillCard>
        </DefaultCarousel>
      </Container>
    </SkillSection>
  )
}

export default Skills
