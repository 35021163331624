import React, { ReactNode, useState } from 'react'

import { Nav, Content } from './style'

type BlogTabItem = {
  id: string;
  name: string;
}

type BlogTabProps = {
  initialActiveTab?: number;
  items: BlogTabItem[];
  children: ReactNode[];
}

function BlogTab ({ initialActiveTab, items, children }: BlogTabProps) {
  const [ activeTab, setActiveTab ] = useState(initialActiveTab)

  function handleClick (index: number) {
    setActiveTab(index)
  }

  return (
    <>
      <Nav>
        <ul>
          {
            items.map((item: BlogTabItem, index: number) => (
              <li key={item.id}>
                <button onClick={() => handleClick(index)} className={activeTab === index ? 'active' : ''}>{item.name}</button>
              </li>
            ))
          }
        </ul>
      </Nav>
      <Content>
        {
          children.map((child: ReactNode, index: number) => (
            <div key={index} className={activeTab === index ? 'active' : ''}>
              { child }
            </div>
          ))
        }
      </Content>
    </>
  )
}

BlogTab.defaultProps = {
  initialActiveTab: 0,
}

export default BlogTab
