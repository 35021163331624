/* eslint-disable max-len */
import React, { MouseEvent } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import scrollTo from 'src/helpers/scrollTo'

// Components
import Button from 'src/components/Button'
import AnimationBanner from 'src/components/AnimationBanner'

// Style
import { BannerSection, BannerContent } from './style'
import { Container } from 'src/styles/grid'

function Banner () {
  return (
    <BannerSection id='main'>
      <Container>
        <BannerContent>
          <h2>
            Seja bem vindo, <br />
            Eu sou <strong>Thiago Salomé</strong><br />
            Desenvolvedor Web
          </h2>
          <AnimationBanner />
          <p>Criei esse site com o objetivo falar um pouco sobre mim e poder compartilhar meus projetos de desenvolvimento.</p>
          <div>
            <Button href='#about' title='Saiba mais' onClick={(evt: MouseEvent) => { scrollTo(evt) }}>Saiba mais</Button>
            <a href='#portfolio' title='Ver projetos' onClick={(evt: MouseEvent) => { scrollTo(evt) }}>
              <span>Ver projetos </span>
              <IoIosArrowForward />
            </a>
          </div>
        </BannerContent>
      </Container>
    </BannerSection>
  )
}

export default Banner
