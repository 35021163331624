import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export default styled.div`
  position: relative;
  margin: 5rem 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }

  > img {
    margin: auto;
    max-width: 240px;
  }

  .gallery-wrapper {
    position: absolute;
    width: 211px;
    top: 50px;
    bottom: 56px;
    left: 50%;
    transform: translateX(-50%);
    background: black;

    .status-bar {
      position: relative;
      top: 0;
      z-index: 2;
    }

    .react-multi-carousel-list {
      padding: 0;
      height: 100%;
      position: relative;

      .react-multi-carousel-track {
        position: absolute;
        top: 0;

        .react-multi-carousel-item {
          padding: 0;
          height: 360px;
          overflow: hidden scroll;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }

      .react-multiple-carousel__arrow {
        position: fixed;
        transform: none;
        order: 2;
        top: 50%;
        transform: translateY(-50%);

        &--left {
          font-size: 0px;
          width: 20px;
          height: 30px;
          background-size: contain;
          padding: 0px;
          transition: width 0.25s ease-in-out 0s;
          left: -50px;
        }

        &--right {
          margin-left: 10px;
          font-size: 0px;
          width: 30px;
          height: 30px;
          padding: 0px;
          transition: all 0.25s ease-in-out 0s;
          right: -50px;
        }

      }

      .react-multi-carousel-dot-list {
        position: fixed;
        bottom: -70px;
        z-index: 5px;
      }
    }

    .navigation-bar {
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }

  .image-gallery {
    position: relative;
    z-index: 1;
    margin: auto;

    /* &-slide-wrapper {
      height: 375px;
    } */

    /* &-left-nav, &-right-nav {
      position: absolute;
      top: 0px;
      transform: none;
      order: 2;
      filter: none;
      top: 50%;
      transform: translateY(-50%);

      &::before, &::after {
        content: "";
        width: 10px;
        display: block;
        position: absolute;
        transition: inherit;
      }

      svg {
        display: none;
      }
    } */

    /* &-left-nav {
      font-size: 0px;
      width: 20px;
      height: 30px;
      background-size: contain;
      padding: 0px;
      transition: width 0.25s ease-in-out 0s;
      left: -35px;

      &::before {
        top: 11px;
        height: 2px;
        left: 0;
        transform: rotate(-36deg);
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      }

      &::after {
        height: 2px;
        bottom: 11px;
        left: 0;
        transform: rotate(36deg);
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      }
    } */

    /* &-right-nav {
      margin-left: 10px;
      font-size: 0px;
      width: 30px;
      height: 30px;
      padding: 0px;
      transition: all 0.25s ease-in-out 0s;
      right: -35px;

      &::before {
        top: 11px;
        height: 2px;
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        transform: rotate(36deg);
        left: 20px;
      }

      &::after {
        height: 2px;
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        bottom: 11px;
        transform: rotate(-36deg);
        left: 20px;
      }
    } */

    /* &-swipe {
      position: absolute;
      overflow-y: scroll;
      overflow-x: hidden;
      top: 0;
      bottom: 0;

      &::-webkit-scrollbar {
        width: 0;
      }
    } */

    /* &-bullets {
      bottom: -75px
    } */

    /* &-bullet {
      border: none;
      box-shadow: 0 1px 0 #cccccc;
      background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};

      &:hover {
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
      }

      &.active {
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      }
    } */

    &-slide {
      outline: none;
      margin-top: 15px;
    }

    &-image {
      max-height: initial !important;
    }

    &-thumbnails {
      padding: 0;

      &-wrapper {
        display: none;
      }
    }

    &-thumbnail {
      width: auto;
      border: none;
    }
  }
`
