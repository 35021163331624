import { graphql, useStaticQuery } from 'gatsby'
import Img, { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import { format, parseISO } from 'date-fns'

// Components
import HeaderSection from 'src/components/HeaderSection'
import BlogTab from 'src/components/Tabs/BlogTab'

// Styles
import { Container } from 'src/styles/grid'
import { BlogSection, BlogGrid, BlogArticle } from './style'

type Article = {
  id: string;
  slug: string;
  title: string;
  description: string;
  publishedAt: string;
  thumbnail: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

type Category = {
  id: string;
  name: string;
  slug: string;
  articles: Article[];
}

function Blog () {
  const data = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 7, sort: {fields: publishedAt, order: DESC}) {
        nodes {
          id
          slug
          title
          description
          publishedAt
          thumbnail {
            childImageSharp {
              fluid (quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allStrapiCategory {
        nodes {
          id
          name
          slug
          articles {
            id
            slug
            title
            description
            publishedAt
            thumbnail {
              childImageSharp {
                fluid (quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const generalTab = {
    id: 'geral',
    name: 'Geral',
    slug: 'geral',
    articles: data.allStrapiArticle.nodes,
  }
  const tabItems = [ generalTab, ...data.allStrapiCategory.nodes ]

  if (data.allStrapiArticle.nodes.length === 0) {
    return <></>
  }

  return (
    <BlogSection id='blog'>
      <Container>
        <HeaderSection centered={true} title='MEU BLOG' subtitle='Assuntos gerais sobre desenvolvimento' />
        <BlogTab items={tabItems}>
          {
            tabItems.map((category: Category) => (
              <BlogGrid key={category.id}>
                {
                  category.articles.map((article: Article) => (
                    <BlogArticle to={`blog/${article.slug}/`} key={article.id}>
                      <figure><Img fluid={article.thumbnail.childImageSharp.fluid} alt={article.title} title={article.title} /></figure>
                      <div>
                        <h4>{article.title}</h4>
                        <span><time dateTime={article.publishedAt}>{format(parseISO(article.publishedAt), 'dd/MM/yyyy')}</time> </span>
                        <p>{article.description}</p>
                      </div>
                    </BlogArticle>
                  ))
                }
              </BlogGrid>
            ))
          }
        </BlogTab>
      </Container>
    </BlogSection>
  )
}

export default Blog
