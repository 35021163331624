import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'

export const Container = styled.nav`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
  border-radius: 7px;
  margin-bottom: ${spaces.lg};

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spaces.xs};

    li {
      flex-basis: 50%;

      button {
        border: none;
        width: 100%;
        cursor: pointer;
        outline: none;
        padding: ${spaces.sm} ${spaces.md};
        text-align: center;
        font-size: ${fonts.xs};
        border-radius: 7px;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};

        &.active {
          background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
        }
      }
    }
  }
`
