/* eslint-disable max-len */
import React from 'react'
import Lottie from 'react-lottie'

// Assets
import ProgramerAnimation from './programer.json'

import { Container } from './style'

function AnimationBanner () {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: ProgramerAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Container>
      <div className='wrapper'>
        <svg width='767' height='607' viewBox='0 0 767 607' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M57.2434 141.354C-43.8506 94.1382 -0.63694 45.8674 105.863 17.8212C105.863 17.8212 500.363 -39.2527 649.363 50.8757C798.363 141.004 811.863 276.769 649.363 412.493C486.863 548.216 166.363 256.715 166.363 256.715C241.363 195.113 117.363 169.433 57.2434 141.354Z' fill='#D05A5B' />
          <path d='M121.864 161.057C31.8635 119.489 43.3635 66.4012 149.864 48.3715C149.864 48.3715 537.864 -11.7276 649.005 104.965C649.005 104.965 781.363 188.122 649.005 438.014C516.646 687.906 221.505 585.758 159.005 585.758C-75.4954 493.606 39.0046 335.845 109.505 266.732C178.864 211.641 138.864 164.563 121.864 161.057Z' fill='#C93D3E' />
        </svg>
      </div>
      <div className='programer'>
        <Lottie
          options={animationOptions}
          width={450}
          height='auto'
          isClickToPauseDisabled={true}
        />
      </div>
    </Container>
  )
}

export default AnimationBanner
