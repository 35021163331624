import React, { useState, MouseEvent } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { GatsbyImageFixedProps } from 'gatsby-image'
import Carousel, { DotProps } from 'react-multi-carousel'

// Components
import HeaderSection from 'src/components/HeaderSection'
import CommentForm from 'src/components/CommentForm'
import Button from 'src/components/Button'

// Helpers
import { trackClick } from 'src/helpers/ga'

// Styles
import { Container } from 'src/styles/grid'
import { TestimonialSection, TestimonialSlider } from './style'

type Comment = {
  comment: string;
  author: string;
  role: string;
  company: string;
  avatar: {
    childImageSharp: GatsbyImageFixedProps;
  };
  active: boolean;
}

function Testimonial () {
  const data = useStaticQuery(graphql`
    query {
      allStrapiComment {
        nodes {
          id
          comment
          author
          role
          company
          avatar {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          active
        }
      }
    }
  `)
  const [ open, setOpen ] = useState(false)
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }

  function handleClick (evt: MouseEvent) {
    evt.preventDefault()
    setOpen(!open)
    const action = !open ? 'Clique em deixe seu comentário' : 'Clique em fechar modal'
    const label = !open ? 'Modal aberto' : 'Modal fechado'
    trackClick('Comentários', action, label)
  }

  const images = data.allStrapiComment.nodes.length > 0 && data.allStrapiComment.nodes.map((comment: Comment, index: number) => {
    return (
      <div key={index} data-avatar={comment.avatar} className='content'>
        <p className='content__testimonial'>{comment.comment}</p>
        <div className='content__info'>
          <p><strong>{comment.author}</strong></p>
          <p>{comment.role} - {comment.company}</p>
        </div>
      </div>
    )
  })

  const CustomDot = ({ onClick, active, index }: DotProps) => {
    const dataAvatar = React.Children.toArray(images)[index as number].props['data-avatar']
    return (
      <li className={active ? 'active' : 'inactive'}>
        <button
          onClick={onClick}
        >
          <Img fluid={dataAvatar.childImageSharp.fluid} />
        </button>
      </li>
    )
  }

  return (
    <>
      <TestimonialSection id='comments'>
        <Container>
          <HeaderSection title='Comentários' subtitle='Opiniões sobre mim' />
          { data.allStrapiComment.nodes.length > 0
            ? (
              <TestimonialSlider>
                <Carousel
                  responsive={responsive}
                  infinite
                  showDots
                  customDot={<CustomDot />}
                  renderDotsOutside
                >
                  {images}
                </Carousel>
              </TestimonialSlider>
            )
            : (
              <p className='no-comments'>Poxa, ainda não tenho nenhum comentário. Que tal deixar o seu?</p>
            )
          }
          <Button title='Deixe seu comentário' href='#' onClick={handleClick}>Deixe seu comentário</Button>
        </Container>
      </TestimonialSection>
      <CommentForm active={open} handleClick={handleClick} />
    </>
  )
}

export default Testimonial
