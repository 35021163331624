import React from 'react'
import { ValueType } from 'react-select'
import { OptionProps } from 'react-select/src/types'

// Styles
import SelectStyles from './style'

type SelectProps = {
  isMulti?: boolean;
  onChange: (options: ValueType<OptionProps, true>) => void;
  options: {
    label: string;
    value: string;
  }[];
}

const Select = ({ isMulti, onChange, options }: SelectProps) => {
  return (
    <SelectStyles
      isMulti={isMulti}
      onChange={onChange}
      options={options}
    />
  )
}

export default Select
