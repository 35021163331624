import React, { useState, useEffect, FocusEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import { FiLoader } from 'react-icons/fi'

// Helpers
import { phone } from 'src/helpers/masks'
import { validateEmail, validatePhone } from 'src/helpers/validations'

// Styles
import { FormContainer } from './style'

type FormValues = {
  name: string;
  email: string;
  phone: string;
  message: string;
}

function ContactForm () {
  const { register, setValue, handleSubmit, errors, getValues, reset }: UseFormMethods<FormValues> = useForm<FormValues>()
  const [ focus, setFocus ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ activeMessage, setActiveMessage ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  function handleFocus (evt: FocusEvent) {
    const input = evt.target as HTMLInputElement
    const inputName = input.name
    setFocus(focus === inputName ? '' : inputName)
  }

  async function sendContactForm (data: FormValues) {
    setLoading(true)
    try {
      await axios.post(`${process.env.STRAPI_URL}/contacts`, { ...data })
      setMessage('Formulário enviado com sucesso!')
      reset()
      setLoading(false)
    } catch (e) {
      setMessage('Erro ao enviar o formulário. Tente novamente.')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (message !== '') {
      setActiveMessage(true)
      setTimeout(() => {
        setActiveMessage(false)
        setMessage('')
      }, 2000)
    }
  }, [ message ])

  return (
    <FormContainer method='post' onSubmit={handleSubmit(sendContactForm)}>
      <h4>Formulário de Contato</h4>
      <div className={`field ${focus === 'name' || getValues('name') ? 'focus' : ''}`}>
        <label htmlFor='name'>Nome Completo</label>
        <input
          ref={register({
            required: 'Nome obrigatório',
          })}
          onFocus={handleFocus}
          onBlur={handleFocus}
          id='name'
          type='text'
          name='name'
        />
        { errors.name && <span className='field__error'>{errors.name.message}</span> }
      </div>
      <div className={`field ${focus === 'email' || getValues('email') ? 'focus' : ''}`}>
        <label htmlFor='email'>Email</label>
        <input
          ref={register({
            required: 'E-mail obrigatório',
            validate: (value: string) => validateEmail(value) || 'E-mail inválido',
          })}
          onFocus={handleFocus}
          onBlur={handleFocus}
          id='email'
          type='email'
          name='email'
        />
        { errors.email && <span className='field__error'>{errors.email.message}</span> }
      </div>
      <div className={`field ${focus === 'phone' || getValues('phone') ? 'focus' : ''}`}>
        <label htmlFor='phone'>Telefone Celular</label>
        <input
          ref={register({
            required: 'Telefone obrigatório',
            validate: (value: string) => validatePhone(value) || 'Telefone inválido',
          })}
          onFocus={handleFocus}
          onBlur={handleFocus}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('phone', phone(event.target.value))}
          id='phone'
          type='text'
          name='phone'
        />
        { errors.phone && <span className='field__error'>{errors.phone.message}</span> }
      </div>
      <div className={`field ${focus === 'message' || getValues('message') ? 'focus' : ''}`}>
        <label htmlFor='message'>Mensagem</label>
        <textarea
          ref={register({
            required: 'Mensagem obrigatória',
          })}
          onFocus={handleFocus}
          onBlur={handleFocus}
          id='message'
          name='message'
          cols={30}
          rows={4}
        />
        { errors.message && <span className='field__error'>{errors.message.message}</span> }
      </div>
      <div className='submit'>
        <input type='submit' value='Enviar mensagem' disabled={loading} />
      </div>
      { loading && <div className='loading'><FiLoader /></div> }
      { activeMessage && <p className='message'>{message}</p> }
    </FormContainer>
  )
}

export default ContactForm
