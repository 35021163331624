import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'
import colors from 'src/styles/colors'

export const SkillSection = styled.section`
  padding: 2rem 0;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};

  @media (min-width: ${breakpoints.md}) {
    padding: 4rem;
  }
`

export const SkillCard = styled.div`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  position: relative;
  padding: ${spaces.xxl};
  margin: ${spaces.xxl} 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .icon {
    height: 65px;
    width: 65px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -32.5px;
    left: 50%;
    transform: translateX(-50%);
  }

  .content {

    h4 {
      font-size: ${fonts.lg};
      text-align: center;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      max-width: 265px;
      font-weight: 600;
      margin: ${spaces.xl} auto;
    }

    ul {
      overflow-y: scroll;
      height: 330px;

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: ${colors.gray[200]};
        border-radius: 10px;
      }

      li {
        display: flex;
        align-items: center;
        margin: ${spaces.sm};
        padding: ${spaces.xs} 0;

        p {
          font-size: ${fonts.sm};
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
          margin-left: ${spaces.md};
        }
      }
    }

  }

`
