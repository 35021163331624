import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import Carousel from 'react-multi-carousel'
import spaces from 'src/styles/spaces'

import breakpoints from 'src/styles/breakpoints'

export const Container = styled(Carousel)`
  padding: 4rem 0;

  // Carousel Item
  .react-multi-carousel-item {
    padding: 0 ${spaces.sm};

    @media (min-width: ${breakpoints.lg}) {
      padding: 0 ${spaces.lg};
    }

    &:first-child {
      padding-left: ${spaces.sm};
    }
    &:last-child {
      padding-right: ${spaces.sm};
    }
  }

  // Carousel Dots
  .react-multi-carousel-dot-list {
    bottom: 15px;

    .react-multi-carousel-dot {

      button {
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
        width: 8px;
        height: 8px;
        border: none;
        transition: width 0.3s ease-in-out;
      }

      &--active {
        button {
          background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
          width: 14px;
          border-radius: 5px;
        }

      }
    }
  }

  // Carousel Arrows
  .react-multiple-carousel__arrow {
    bottom: 0;
    background: transparent;
    font-weight: bold;
    z-index: 1;

    &::before {
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    }
  }
`
