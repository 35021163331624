import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

const widthFrame = 255
const heightFrame = 310

export const AboutSection = styled.section`
  padding: 0 0 6rem;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};

  .perfil {
    .gatsby-image-wrapper {
      margin: 0 auto;
    }
  }

  .description {
    font-size: ${fonts.md};
    line-height: 1.6;
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
    margin: ${spaces.xl} 0 ${spaces.xxl};

    @media (min-width: ${breakpoints.md}) {
      font-size: ${fonts.sm};
    }

    p {
      margin-bottom: ${spaces.md}
    }
  }

  button {
    margin: auto;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 6rem 0;

    .perfil {
      position: relative;

      &::before, &::after {
        content: '';
        position: absolute;
      }

      &::before {
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        width: ${widthFrame}px;
        height: ${heightFrame}px;
        top: 50px;
        left: -30px;
        z-index: 1;
      }

      > div {
        z-index: 3;
      }

      &::after {
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
        width: ${widthFrame - 20}px;
        height: ${heightFrame - 20}px;
        top: 60px;
        left: -20px;
        z-index: 2;
      }
    }

    button {
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 10rem 0;

    .perfil {

      &::before {
        left: -40px;
        width: ${widthFrame - 50}px;
        height: ${heightFrame - 50}px;
      }

      &::after {
        left: -30px;
        width: ${widthFrame - 70}px;
        height: ${heightFrame - 70}px;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    .perfil {

      &::before {
        left: -70px;
        width: ${widthFrame}px;
        height: ${heightFrame}px;
      }

      &::after {
        left: -60px;
        width: ${widthFrame - 20}px;
        height: ${heightFrame - 20}px;
      }
    }
  }
`
