import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ExperienceCard from 'src/components/ExperienceCard'
import DefaultCarousel from 'src/components/DefaultCarousel'

// Styles
import { Container } from 'src/styles/grid'
import { ExperienceSection } from './style'

function Experience () {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAcademic(sort: {order: DESC, fields: createdAt}) {
        nodes {
          id
          period
          place
          title
          description
        }
      }
      allStrapiProfessional(sort: {order: DESC, fields: createdAt}) {
        nodes {
          id
          place
          description
          period
          title
        }
      }
    }
  `)
  return (
    <ExperienceSection id='experience'>
      <Container>
        <DefaultCarousel
          lg={{ items: 2, partialVisibilityGutter: 0, slidesToSlide: 1 }}
          xl={{ items: 2, partialVisibilityGutter: 0, slidesToSlide: 1 }}
        >
          <ExperienceCard title='Experiência Acadêmica' data={data.allStrapiAcademic.nodes} />
          <ExperienceCard title='Experiência Profissional' data={data.allStrapiProfessional.nodes} />
        </DefaultCarousel>
      </Container>
    </ExperienceSection>
  )
}

export default Experience
