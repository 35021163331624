import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

import { SocialNetworkContainer } from 'src/components/SocialNetwork/style'

export const ContactSection = styled.section`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
  padding: 2rem 0;

  .description, .email{
    text-align: center;
  }

  .description {
    font-size: ${fonts.md};
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    margin: ${spaces.xxl} 0;

    @media (min-width: ${breakpoints.md}) {
      font-size: ${fonts.sm};
    }
  }

  .email {
    font-size: ${fonts.sm};
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    margin: ${spaces.xxl} 0;

    strong {
      text-transform: uppercase;
      font-weight: 600;
      display: block;
      margin-bottom: ${spaces.xs};
    }
  }

  ${SocialNetworkContainer} {
    margin: ${spaces.xxl} 0;;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 5rem 0;

    .description, .email{
      text-align: left;
    }

    ${SocialNetworkContainer} {
      justify-content: flex-start;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 6rem 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 8rem;
  }
`
