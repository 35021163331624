import React from 'react'

// Styles
import { Container } from './style'

type TabItemProps = {
  id: string;
  label: string;
}

type TabProps = {
  list: TabItemProps[];
  state: string;
  setState: (value: string) => void;
}

const Tab = ({ list, state, setState }: TabProps) => {
  return (
    <Container>
      <ul>
        {
          list.map((tabItem: TabItemProps) => (
            <li key={tabItem.id}>
              <button className={state === tabItem.id ? 'active' : ''} onClick={() => setState(tabItem.id)}>{tabItem.label}</button>
            </li>
          ))
        }
      </ul>
    </Container>
  )
}

export default Tab
