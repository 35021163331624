import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'

export const ButtonContainer = styled.a`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
  border-radius: 3px;
  padding: ${spaces.md} ${spaces.lg};
  text-transform: uppercase;
  color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  max-width: 200px;
  font-weight: bold;
  font-size: ${fonts.xs};
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: perspective(1px) translateZ(0) translateY(0);

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 100%;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
  }


  &:hover {
    transform: translateY(-3px);

    &::before {
      transform: scale(2);
    }
  }

  svg {
    width: ${spaces.lg};
    height: ${spaces.lg};
  }

  span {
    margin-left: ${spaces.sm};
  }
`
