import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

export const BannerSection = styled.section`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  min-height: auto;
  width: 100%;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 580px;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      border-bottom: 140px solid ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      border-left: 90px solid transparent;
      border-right: 90px solid transparent;
      left: -100px;
      top: -30px;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 670px;
  }
`

export const BannerContent = styled.div`
  padding: 10rem 0 5rem;

  h2 {
    font-size: ${fonts.xl};
    font-weight: 200;
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};

    strong {
      font-weight: bold;
    }
  }

  p {
    font-size: ${fonts.md};
    margin: ${spaces.xl} 0;
    line-height: 1.6;
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};

    @media (min-width: ${breakpoints.md}) {
      font-size: ${fonts.sm};
    }
  }

  > div {
    display: flex;
    align-items: center;

    a:last-child {
      text-transform: uppercase;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      font-size: ${fonts.xs};
      font-weight: bold;
      margin: 0 ${spaces.lg};
      display: inline-flex;
      align-items: center;
      transition: all 0.3s ease-in-out;

      svg {
        height: ${spaces.lg};
        width: ${spaces.lg};
        margin-left: ${spaces.xs};
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        transform: scale(1.02);

        svg {
          transform: translateX(3px);
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 455px;

    h2 {
      font-size: ${fonts.xxl};
    }

  }
`
