/* eslint-disable max-len */
import React, { useState } from 'react'

// Components
import HeaderSection from 'src/components/HeaderSection'
import ProjectList from './ProjectList'
import ProjectView from './ProjectView'
import ProjectDetails from './ProjectDetails'

// Types
import { ProjectData } from './types'

// Styles
import { Container, Row, Col } from 'src/styles/grid'
import { PortfolioSection } from './style'

function Portfolio () {
  const [ projectData, setProjectData ] = useState<ProjectData>({
    title: '',
    description: '',
    features: '',
    link: '',
    github: '',
    date: '',
    images: [],
  })

  return (
    <PortfolioSection id='portfolio'>
      <Container>
        <HeaderSection centered={true} title='Meu portifólio' subtitle='Meus projetos Web e Mobile' />
        <Row>
          <Col md={6} lg={4}>
            <ProjectList setProjectData={setProjectData} />
          </Col>
          <Col md={6} lg={4} id='smartphone'>
            <ProjectView projectData={projectData} />
          </Col>
          <Col md={12} lg={4}>
            <ProjectDetails projectData={projectData} />
          </Col>
        </Row>
      </Container>
    </PortfolioSection>
  )
}

export default Portfolio
