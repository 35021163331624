import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import Select from 'react-select'
import colors from 'src/styles/colors'
import spaces from 'src/styles/spaces'

export default styled(Select)`
  margin-bottom: ${spaces.lg};

  > div[class*="control"] {
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
    border-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
    border-radius: 7px;
    min-height: 40px;

    &:hover {
      border: none;
    }

    div[class*="multiValue"] {
      background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};

      div {
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};

        svg {
          fill: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
        }
      }
    }

    input {
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary} !important;
    }
  }

  > div[class*="menu"] {
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};

    div {
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: ${colors.gray[200]};
        border-radius: 10px;
      }

      div {
        background-color: transparent !important;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary} !important;

        &:hover {
          background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary} !important;
        }
      }
    }
  }
`
