import React from 'react'

// Styles
import { ExperienceContainer, ExperienceHeader, ExperienceBody } from './style'

type Item = {
  id: string;
  place: string;
  period: string;
  title: string;
  description: string;
}

type ExperienceCardProps = {
  title: string;
  data: Item[];
}

function ExperienceCard ({ title, data }: ExperienceCardProps) {
  return (
    <ExperienceContainer>
      <ExperienceHeader>
        <h3>{title}</h3>
      </ExperienceHeader>
      <ExperienceBody>
        <ul>
          {
            data.map((item: Item) => (
              <li key={item.id}>
                <div className='school'>
                  <p>{item.period}</p>
                  <p><strong>{item.place}</strong></p>
                </div>
                <div className='description'>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </li>
            ))
          }
        </ul>
      </ExperienceBody>
    </ExperienceContainer>
  )
}

export default ExperienceCard
