import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import colors from 'src/styles/colors'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

export const ExperienceContainer = styled.div`
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  border-radius: ${spaces.md};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  height: 100%;
`

export const ExperienceHeader = styled.header`
  border-bottom: 0.2px solid ${colors.gray[200]};
  padding: ${spaces.lg} ${spaces.xl};

  h3 {
    text-transform: uppercase;
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    font-size: ${fonts.md};
    text-align: center;
    font-weight: 600;

    @media (min-width: ${breakpoints.lg}) {
      font-size: ${fonts.lg};
      text-align: left;
    }
  }
`

export const ExperienceBody = styled.div`
  padding: ${spaces.xl} ${spaces.lg};

  li {
    display: flex;
    padding-bottom: ${spaces.xxl};

    .school {
      display: none;
    }

    .school, .description {

      h4, p {
        font-size: ${fonts.sm};
        line-height: 1.6;

        @media (min-width: ${breakpoints.lg}) {
          font-size: ${fonts.sm};
        }
      }

      h4 {
        text-transform: uppercase;
        margin-bottom: ${spaces.md};
        font-weight: 500;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      }

      p {
        font-weight: 400;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};

        strong {
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }

    .school {
      flex-shrink: 0;
      margin-right: 4rem;
      flex-basis: 85px;
      max-width: 85px;
      position: relative;

      &::before, &::after {
        content: '';
        top: 0;
        position: absolute;
        right: -2rem;
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      }

      &::before {
        bottom: -30px;
        width: 1px;
      }

      &::after {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        transform: translateX(50%);
      }
    }

    .description {
      flex-shrink: 1;
      flex-basis: auto;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: ${spaces.xl};

    li {
      .school {
        display: block;
      }
    }
  }
`
