import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'

// Hooks
import useWindowSize from 'src/hooks/useWindowSize'

// Styles
import { Container } from './style'

type Breakpoint = {
  items: number;
  slidesToSlide?: number;
  partialVisibilityGutter?: number;
}

type DefaultCarouselProps = {
  children: ReactNode[];
  infinite?: boolean;
  sm: Breakpoint;
  md: Breakpoint;
  lg: Breakpoint;
  xl: Breakpoint;
}

const WIDTH_MD = 768
const WIDTH_LG = 992
const WIDTH_XL = 1200

function DefaultCarousel ({ children, infinite, sm, md, lg, xl }: DefaultCarouselProps) {
  const windowSize = useWindowSize()
  const carouselRef = useRef<Carousel>()
  const [ breakpoint, setBreakpoint ] = useState<Breakpoint>()
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: xl.items,
      slidesToSlide: xl.slidesToSlide,
      partialVisibilityGutter: xl.partialVisibilityGutter,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.items,
      slidesToSlide: lg.slidesToSlide,
      partialVisibilityGutter: lg.partialVisibilityGutter,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.items,
      slidesToSlide: md.slidesToSlide,
      partialVisibilityGutter: md.partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.items,
      slidesToSlide: sm.slidesToSlide,
      partialVisibilityGutter: sm.partialVisibilityGutter,
    },
  }

  function moveToFirstItem () {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goToSlide(0)
    }
  }

  useEffect(() => {
    if (windowSize.width !== undefined) {
      if (windowSize.width >= WIDTH_XL) {
        setBreakpoint(xl)
      } else if (windowSize.width >= WIDTH_LG) {
        setBreakpoint(lg)
      } else if (windowSize.width >= WIDTH_MD) {
        setBreakpoint(md)
      } else {
        setBreakpoint(sm)
      }
    }

    moveToFirstItem()
  }, [ windowSize ])

  useEffect(() => {
    moveToFirstItem()
  }, [ children ])

  return (
    <Container
      ref={carouselRef as MutableRefObject<Carousel>}
      infinite={infinite}
      showDots={breakpoint && (breakpoint.items < children.length)}
      partialVisible={true}
      responsive={responsive}
    >
      {children}
    </Container>
  )
}

DefaultCarousel.defaultProps = {
  infinite: false,
  sm: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  md: {
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  lg: {
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  xl: {
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
}

export default DefaultCarousel
