import React from 'react'
import { AiOutlineLink, AiOutlineGithub } from 'react-icons/ai'

// Types
import { ProjectData } from '../types'

// Styles
import ProjectDetailsStyle from './style'

type ProjectDetailsProps = {
  projectData: ProjectData;
}

const ProjectDetails = ({ projectData }: ProjectDetailsProps) => {
  return (
    <ProjectDetailsStyle>
      <h4>{projectData.title}</h4>
      <div>
        <p>{projectData.description}</p>
      </div>
      <div>
        <p><strong>Tecnologias utilizadas:</strong></p>
        <p>{projectData.features}</p>
      </div>
      <div>
        <p><strong>Data do projeto:</strong></p>
        <p>{projectData.date}</p>
      </div>
      { (projectData.link || projectData.github) && (
        <div className='links'>
          <p><strong>Ver projeto:</strong></p>
          { projectData.link && (
            <a
              href={projectData.link}
              target='_blank'
              rel='noopener noreferrer'
              title='Link do Projeto'
            >
              <AiOutlineLink />
            </a>
          )}
          { projectData.github && (
            <a
              href={projectData.github}
              target='_blank'
              rel='noopener noreferrer'
              title='Link do GitHub'
            >
              <AiOutlineGithub />
            </a>
          )}
        </div>
      )}
    </ProjectDetailsStyle>
  )
}

export default ProjectDetails
