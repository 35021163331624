import React, { useState, useEffect, FocusEvent, ChangeEvent, MouseEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import { FiLoader } from 'react-icons/fi'
import { IoMdClose } from 'react-icons/io'
import { TiUser } from 'react-icons/ti'

// Helpes
import { validateImageSize, validateImageType } from 'src/helpers/validations'

// Styles
import { FormModal, FormContainer } from './style'

type CommentForm = {
  active: boolean;
  handleClick: (evt: MouseEvent) => void;
}

type FormValues = {
  avatar: FileList;
  comment: string;
  author: string;
  role: string;
  company: string;
}

function CommentForm ({ active, handleClick }: CommentForm) {
  const { register, handleSubmit, errors, getValues, reset }: UseFormMethods<FormValues> = useForm<FormValues>()
  const [ focus, setFocus ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ activeMessage, setActiveMessage ] = useState(false)
  const [ dataUrl, setDataUrl ] = useState<string | ArrayBuffer | null>()
  const [ loading, setLoading ] = useState(false)

  function handleFocus (evt: FocusEvent) {
    const input = evt.target as HTMLInputElement
    const inputName = input.name
    setFocus(focus === inputName ? '' : inputName)
  }

  function handleChangeAvatar (evt: ChangeEvent) {
    const input = evt.target as HTMLInputElement
    const file = input.files && input.files[0]
    if (input && file) {
      const reader: FileReader = new window.FileReader()

      reader.onload = function (e: ProgressEvent<FileReader>) {
        setDataUrl(e.target?.result)
      }

      reader.readAsDataURL(file)
    }
  }

  function createFormData (avatar: File, idComment: string) {
    const formData = new window.FormData()
    formData.append('files', avatar)
    formData.append('refId', idComment) // O Id da entrada que a imagem será vinculada
    formData.append('ref', 'comment') // O nome do tipo de conteúdo que a imagem será vinculada
    formData.append('field', 'avatar') // O campo dentro do tipo de conteúdo que receberá o valor da imagem
    return formData
  }

  const resetValues = () => {
    setDataUrl(null)
    reset()
  }

  async function uploadImage (avatar: File, id: string) {
    try {
      const formData = createFormData(avatar, id)
      await axios.post(`${process.env.STRAPI_URL}/upload`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      setMessage('Agradeço pelo seu comentário. Logo ele será postado.')
      setLoading(false)
      resetValues()
    } catch (error) {
      setMessage('Erro ao fazer o upload da imagem. Tente novamente mais tarde.')
      setLoading(false)
    }
  }

  async function sendCommentForm (data: FormValues) {
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.STRAPI_URL}/comments`, {
        comment: data.comment,
        author: data.author,
        role: data.role,
        company: data.company,
      })
      uploadImage(data.avatar[0], response.data.id)
    } catch (e) {
      setMessage('Erro ao enviar o formulário. Tente novamente mais tarde.')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (message !== '') {
      setActiveMessage(true)
      setTimeout(() => {
        setActiveMessage(false)
        setMessage('')
      }, 2000)
    }
  }, [ message ])

  return (
    <FormModal active={active}>
      <span className='close' onClick={handleClick}><IoMdClose /></span>
      <FormContainer method='post' onSubmit={handleSubmit(sendCommentForm)}>
        <h4>Deixe seu comentário</h4>
        <div className='field--avatar'>
          <label htmlFor='avatar'>
            <span>
              {
                dataUrl ? <img src={dataUrl} alt='Imagem de Perfil' title='Imagem de Perfil' /> : <TiUser />
              }
            </span>
            <span>Imagem de Perfil</span>
          </label>
          <input
            ref={register({
              required: 'Imagem obrigatória',
              validate: {
                imageSize: (value: FileList) => validateImageSize(value[0].size) || 'O tamanho da imagem deve ser menor que 1MB.',
                imageType: (value: FileList) => validateImageType(value[0].name) || 'O formato da imagem não é válido.',
              },
            })}
            onChange={handleChangeAvatar}
            accept='image/png,image/jpg,image/jpeg'
            type='file'
            name='avatar'
            id='avatar'
          />
          { errors.avatar && <span className='field__error field__error--center'>{errors.avatar.message}</span> }
        </div>
        <div className={`field ${focus === 'comment' || getValues('comment') ? 'focus' : ''}`}>
          <label htmlFor='comment'>Comentário</label>
          <textarea
            ref={register({
              required: 'Comentário obrigatório',
              minLength: {
                value: 60,
                message: 'O campo comentário deve ter entre 60 e 140 caracteres.',
              },
              maxLength: {
                value: 140,
                message: 'O campo comentário deve ter entre 60 e 140 caracteres.',
              },
            })}
            onFocus={handleFocus}
            onBlur={handleFocus}
            id='comment'
            name='comment'
            cols={30}
            rows={3}
          />
          { errors.comment && <span className='field__error'>{errors.comment.message}</span> }
        </div>
        <div className={`field ${focus === 'author' || getValues('author') ? 'focus' : ''}`}>
          <label htmlFor='name'>Nome e sobrenome</label>
          <input
            ref={register({
              required: 'Nome e sobrenome obrigatórios',
            })}
            onFocus={handleFocus}
            onBlur={handleFocus}
            id='author'
            type='text'
            name='author'
          />
          { errors.author && <span className='field__error'>{errors.author.message}</span> }
        </div>
        <div className={`field ${focus === 'role' || getValues('role') ? 'focus' : ''}`}>
          <label htmlFor='role'>Cargo</label>
          <input
            ref={register({
              required: 'Cargo obrigatório',
            })}
            onFocus={handleFocus}
            onBlur={handleFocus}
            id='role'
            type='text'
            name='role'
          />
          { errors.role && <span className='field__error'>{errors.role.message}</span> }
        </div>
        <div className={`field ${focus === 'company' || getValues('company') ? 'focus' : ''}`}>
          <label htmlFor='company'>Empresa</label>
          <input
            ref={register({
              required: 'Empresa obrigatória',
            })}
            onFocus={handleFocus}
            onBlur={handleFocus}
            id='company'
            type='text'
            name='company'
          />
          { errors.company && <span className='field__error'>{errors.company.message}</span> }
        </div>

        <div className='submit'>
          <input type='submit' value='Enviar comentário' disabled={loading} />
        </div>
        { loading && <div className='loading'><FiLoader /></div> }
        { activeMessage && <p className='message'>{message}</p> }
      </FormContainer>
    </FormModal>
  )
}

export default CommentForm
