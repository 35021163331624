import React from 'react'

// Styles
import { HeaderContainer } from './style'

type HeaderSection = {
  title: string;
  subtitle: string;
  centered?: boolean;
}

function HeaderSection ({ title, subtitle, centered }: HeaderSection) {
  return (
    <HeaderContainer centered={centered}>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </HeaderContainer>
  )
}

HeaderSection.defaultProps = {
  centeder: false,
}

export default HeaderSection
